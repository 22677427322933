<template>
  <div class="excerpt-detail-page">
    <div class="excerpt-detail-main">
      <van-skeleton :row="3" :loading="loading">
        <div class="excerpt-detail" v-if="excerpt">
          <div class="excerpt-content" v-html="initExcerpt(excerpt.content)"></div>
          <p class="excerpt-from" v-if="excerpt.author || excerpt.book || excerpt.chapter">
            ——
            <span v-if="excerpt.author">{{ excerpt.author }}</span>
            <span v-if="excerpt.book">《{{ excerpt.book }}》</span>
            <span v-if="excerpt.chapter">第{{ excerpt.chapter }}章</span>
          </p>
        </div>
      </van-skeleton>
    </div>
  </div>
</template>

<script>
import {initExcerpt} from '@/util/init-data'

import {getExcerptDetail} from '@/api/data'
import {pageScrollTop} from '@/util/common'

export default {
  name: 'ExcerptDetailPage',
  data() {
    return {
      id: null,
      excerpt: null,
      loading: false
    }
  },
  computed: {},
  components: {},
  activated() {
    this.initData()
    if (!this.excerpt) {
      this.getExcerptDetail()
    }
    pageScrollTop(0)
  },
  mounted() {
  },
  methods: {
    initExcerpt,
    initData() {
      this.id = this.$route.query.id
      this.excerpt = this.$route.params.excerpt || null
    },
    async getExcerptDetail() {
      this.loading = true
      const res = await getExcerptDetail({
        id: this.id
      })
      this.loading = false
      this.excerpt = res.data || null
    }
  }
}
</script>

<style lang="less">
.excerpt-detail-page {
  padding: 0 1rem 1rem 1rem;

  .excerpt-detail {
    padding: 1rem;

    .excerpt-content {
      font-size: 1.6rem;
      text-indent: 3.2rem;
      line-height: 3rem;

      p {
        padding: .5rem 0;
      }
    }

    .excerpt-from {
      margin-top: 1rem;
      font-size: 1.2rem;
      line-height: 3rem;
      text-align: right;
    }
  }
}
</style>
