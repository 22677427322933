<template>
  <div class="days">
    <van-pull-refresh v-model="refreshLoading" @refresh="getDayList">
      <!--无数据-->
      <no-data v-if="!loading && !dayList.length"></no-data>
      <!--数据列表-->
      <day-data-list :data-list="dayList" :loading="loading" @handleMore="handleMore"></day-data-list>
    </van-pull-refresh>
    <!--侧边功能按钮-->
    <side-tool-button :icon="'icon-add'" :bottom="'9.5rem'" @click="handleAdd"></side-tool-button>
    <!--弹出层-->
    <van-popup v-model="popupInfo.show" position="bottom" round @closed="hidePopup">
      <!--弹出层标题-->
      <h4 class="popup-title text-center" v-if="popupInfo.title">{{ popupInfo.title }}</h4>
      <!--弹出层内容-->
      <div class="popup-content p-1" v-if="popupInfo.type === 1">
        <setting-plug @click="handleEdit" label="编辑" icon="icon-bianji"></setting-plug>
        <setting-plug @click="setTop" :label="selectData.isTop ? '取消置顶' : '置顶'"
                      :icon="selectData.isTop ? 'icon-quxiaozhiding' : 'icon-zhiding'"></setting-plug>
        <setting-plug @click="handleDelete" label="删除" icon="icon-shanchu-m"></setting-plug>
      </div>
      <!--弹出层内容-->
      <div class="popup-content p-1" v-if="popupInfo.type === 2">
        <setting-plug label="事件">
          <wi-input v-model="selectData.label" placeholder="请输入事件名（<10个字符）" :max-length="10"></wi-input>
        </setting-plug>
        <setting-plug label="时间" class="mt-1">
          <van-datetime-picker
              v-model="selectData.date"
              type="date"
              :min-date="minDate"
              :max-date="maxDate"
              :show-toolbar="false"/>
        </setting-plug>
        <wi-button @click="updateTheDay">确认</wi-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {dateFormat, deepCopy} from '@/util/common'
import {getDayList, updateTheDay, delTheDay, setTopTheDay} from '@/api/data'

import dayDataList from './components/day-data-list'

export default {
  name: 'Days',
  components: {dayDataList},
  data() {
    return {
      dayList: [], // 纪念日数据列表
      selectData: null, // 已选择的数据
      minDate: new Date(1960, 0, 1), // 纪念日范围最大值
      maxDate: new Date(), // 纪念日范围最小值
      popupInfo: { // 弹出层的类型
        show: false,
        title: '', // 标题
        type: '' // 1 操作  2 修改
      },
      loading: false,
      refreshLoading: false
    }
  },
  activated() {
    if (!this.dayList.length) { // 当前页面有数据缓存
      this.dayList = []
      this.getDayList()
    }
  },
  computed: {},
  methods: {
    async getDayList() { // 获取纪念日列表
      this.loading = true
      const res = await getDayList()
      this.loading = false
      this.refreshLoading = false
      this.dayList = res.data || []
    },
    async setTop() { // 设置置顶
      const res = await setTopTheDay({
        id: this.selectData.id,
        isTop: this.selectData.isTop ? 0 : 1
      })
      this.$toast(res.msg)
      await this.getDayList()
      this.hidePopup()
    },
    async updateTheDay() { // 修改纪念日
      if (!this.selectData.label) {
        this.$toast('请输入事件名！')
        return
      }
      const res = await updateTheDay({
        id: this.selectData.id,
        date: dateFormat(this.selectData.date, 'YYYY-MM-DD'),
        label: this.selectData.label
      })
      this.$toast(res.msg)
      await this.getDayList()
      this.hidePopup()
    },
    async delTheDay(id) { // 删除纪念日
      const res = await delTheDay({id})
      this.$toast(res.msg)
      await this.getDayList()
    },
    handleMore(index) { // 更多
      this.selectData = deepCopy(this.dayList[index])
      this.selectData.date = new Date(this.selectData.date)
      this.showPopup(1, this.dayList[index].label)
    },
    handleEdit() { // 修改操作
      this.showPopup(2, '修改')
    },
    handleAdd() { // 添加操作
      this.showPopup(2, '添加')
      this.selectData = {
        id: null,
        label: '',
        date: new Date()
      }
    },
    handleDelete() { // 删除操作
      let id = this.selectData.id
      this.hidePopup()
      this.$dialog.confirm({
        closeOnClickOverlay: true,
        message: '是否删除该纪念日？',
      }).then(_ => {
        this.delTheDay(id)
      }).catch(_ => {
        // 虽然不做什么 但是以防报错还是留着
      })
    },
    showPopup(type, title) { // 显示弹窗
      this.popupInfo = {
        show: true,
        title,
        type
      }
    },
    hidePopup() { // 隐藏弹窗
      this.popupInfo = {
        show: false,
        title: '',
        type: ''
      }
      this.selectData = null
    }
  }
}
</script>

