<template>
  <div>
    <ul class="excerpt-data-list" v-if="loading">
      <li v-for="item in 6" :key="item" class="mb-1">
        <div class="p-1 mb-1">
          <van-skeleton :row="2"></van-skeleton>
        </div>
        <p class="placeholder-label"></p>
      </li>
    </ul>
    <ul class="excerpt-data-list" v-else>
      <li class="excerpt-data-list-item dfc" v-for="(item, i) in dataList" :key="i" @click="showExcerpt(item)">
        <div class="excerpt-detail">
          <div class="excerpt-content-wrap wi-card">
            <div class="excerpt-content" v-html="initExcerpt(item.content)"></div>
          </div>
          <div class="excerpt-info dfr">
            <p class="excerpt-info-label" v-if="item.groupLabel">
              # {{ item.groupLabel }}
            </p>
            <p class="excerpt-info-from text-ellipsis" v-if="item.author || item.book || item.chapter">
              ——
              <span v-if="item.author">{{ item.author }}</span>
              <span v-if="item.book">《{{ item.book }}》</span>
              <span v-if="item.chapter">第{{ item.chapter }}章</span>
            </p>
          </div>
        </div>
        <i class="icon-more iconfont icon-gengduo-shuxiang" @click.stop="handMore(i)"></i>
      </li>
    </ul>
  </div>

</template>

<script>
import {initExcerpt} from '@/util/init-data'

export default {
  name: '',
  components: {},
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    dataList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {}
  },
  computed: {},
  created() {
  },
  methods: {
    initExcerpt,
    showExcerpt(data) {
      this.$router.push({
        name: 'ExcerptDetailPage',
        query: {
          id: data.id
        },
        params: {
          excerpt: data
        }
      })
    },
    handMore(index) {
      this.$emit('handleMore', index)
    }
  }
}
</script>

<style scoped lang="less">
.excerpt-data-list {
  padding: 0 1rem;
}

.excerpt-data-list-item {
  position: relative;
  justify-content: space-between;
  transition: all 1s;
  margin-bottom: 1rem;

  .icon-more {
    position: absolute;
    top: .3rem;
    right: 0;
    z-index: 3;
    padding: .5rem .2rem;
    font-size: 2rem;
  }
}

.excerpt-detail {
  .excerpt-content-wrap {
    padding: 1rem 2rem 1rem 1rem;
  }

  .excerpt-content {
    font-size: 1.4rem;
    line-height: 2.8rem;
    text-indent: 2.8rem;
  }

  .excerpt-info {
    font-size: 1.2rem;
    margin: 1rem 0;
    justify-content: space-between;
    line-height: 2rem;
    height: 2rem;

    .excerpt-info-from {
      max-width: 60%;
      text-align: right;
    }

    .excerpt-info-label {
      font-size: 1rem;
      padding: 0 1rem;
      border-radius: 1rem;
      background-color: @excerpt-list-label-background-color;
      color: @excerpt-list-label-color;
    }

  }
}

.placeholder-label{
  height: 2rem;
  border-radius: 1rem;
  width: 8rem;
  background-color: @gray-3;
}
</style>
