<template>
  <div class="login dfr-XY-center">
    <div class="login-main dfc-Y-center">
      <div class="login-tab">
        <p v-for="(item, i) in typeList"
           :key="item.id"
           @click="changeType(item.key)"
           class="login-tab-item text-center"
           :class="{active: item.key === type}">
          <span>{{ item.label }}</span>
        </p>
      </div>
      <div class="login-content" :class="'show-' + type">
        <div class="login-operation login">
          <wi-input
              :placeholder="'请输入用户名'"
              v-model="userName"
              :label-icon="'icon-RectangleCopy'"
              :show-err-icon="!checkValue('userName')"
              :max-length="regexList.userName.max"></wi-input>
          <wi-input
              :type="'password'"
              :placeholder="'请输入密码'"
              v-model="password"
              :label-icon="'icon-RectangleCopy1'"
              :max-length="regexList.password.max"
              :show-err-icon="!checkValue('password')"></wi-input>
          <wi-button @click="login" class="mt-1">登录</wi-button>
        </div>
        <div class="login-operation register">
          <wi-input
              :placeholder="'请输入用户名'"
              v-model="userName"
              :label-icon="'icon-RectangleCopy'"
              :max-length="regexList.userName.max"
              :show-err-icon="!checkValue('userName')"></wi-input>
          <wi-input
              :placeholder="'请输入邮箱'"
              v-model="eMail"
              :label-icon="'icon-xinfeng'"
              :max-length="regexList.eMail.max"
              :show-err-icon="!checkValue('eMail')"></wi-input>
          <wi-input
              :type="'password'"
              :placeholder="'请输入密码'"
              v-model="password"
              :label-icon="'icon-RectangleCopy1'"
              :max-length="regexList.password.max"
              :show-err-icon="!checkValue('password')"></wi-input>
          <wi-input
              :type="'password'"
              :placeholder="'请确认密码'"
              v-model="rePassword"
              :label-icon="'icon-RectangleCopy1'"
              :max-length="regexList.password.max"
              :show-err-icon="!checkValue('rePassword')"></wi-input>
          <wi-button @click="register" class="mt-1">注册</wi-button>
        </div>
      </div>
    </div>
    <div class="copyright text-center">
      <a href="https://beian.miit.gov.cn">鄂ICP备2021007451号-2</a>
    </div>
  </div>
</template>

<script>
import md5 from 'js-md5'
import {login, register, getUserInfo} from '@/api/user'
import {setToken, getToken} from '@/util/token'
export default {
  name: 'Login',
  components: {},
  data() {
    return {
      type: 'login',
      typeList: [
        {
          key: 'login',
          label: 'Login'
        },
        {
          key: 'register',
          label: 'Register'
        },
      ],
      userName: '',
      eMail: '',
      password: '',
      rePassword: '',
      regexList: { // 正则表
        userName: {
          min: 2,
          max: 8,
          regex: /^[\u4E00-\u9FA5A-Za-z0-9_]+$/,
          tip: '用户名长度为2-8位，仅允许中文、英文、数字以及下划线。'
        },
        password: {
          min: 6,
          max: 16,
          regex: /(?=.*([a-zA-Z].*))(?=.*[0-9].*)[a-zA-Z0-9-*/+.~!@#$%^&*()]{6,16}$/,
          tip: '密码长度为6-16位，仅允许数字、英文、特殊符号，且必须包含数字及英文。'
        },
        eMail: {
          min: 0,
          max: 255,
          regex: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
        }
      }
    }
  },
  created() {},
  computed: {},
  methods: {
    async login() {
      if (!this.userName) {
        this.$toast('请输入用户名')
        return
      }
      if (!this.password) {
        this.$toast('请输入密码')
        return
      }
      if (!this.checkValue('userName')) {
        this.$toast(this.regexList.userName.tip)
        return
      }
      if (!this.checkValue('password')) {
        this.$toast(this.regexList.password.tip)
        return
      }
      let res = await login({
        userName: this.userName,
        password: md5(this.password)
      })
      if (res.code === 0) {
        setToken(res.data.token)
        await this.$router.push('/home')
        await this.getUserInfo()
      }
    },
    async register() {
      if (!this.userName) {
        this.$toast('请输入用户名！')
        return
      }
      if (!this.eMail) {
        this.$toast('请输入邮箱！')
        return
      }
      if (!this.password) {
        this.$toast('请输入密码！')
        return
      }
      if (!this.rePassword) {
        this.$toast('请确认密码！')
        return
      }
      if (!this.checkValue('userName')) {
        this.$toast(this.regexList.userName.tip)
        return
      }
      if (!this.checkValue('eMail')) {
        this.$toast('请输入正确的邮箱地址！')
        return
      }
      if (!this.checkValue('password')) {
        this.$toast(this.regexList.password.tip)
        return
      }
      if (!this.checkValue('rePassword')) {
        this.$toast('确认密码有误！')
        return
      }
      const res = register({
        userName: this.userName,
        eMail: this.eMail,
        password: md5(this.password)
      })
      if (res.code === 0) {
        this.$toast(res.msg)
        let userName = this.userName
        this.changeType('login')
        this.userName = userName
      }
    },
    changeType(key) {
      this.type = key
      this.clearInfo()
    },
    clearInfo() {
      this.userName = ''
      this.eMail = ''
      this.password = ''
      this.rePassword = ''
    },
    checkValue(type) {
      let flag = true
      let value = this[type]
      if (!this[type]) {
        return flag
      } else {
        if (type === 'rePassword') {
          flag = value === this.password
          return flag
        } else {
          let regex = this.regexList[type].regex
          flag = value.length >= this.regexList[type].min && value.length <= this.regexList[type].max && regex.test(value)
          return flag
        }
      }
    },
    async getUserInfo() {
      const res = await getUserInfo()
      await this.$store.dispatch('setUserInfo', res.data)
    }
  }
}
</script>

<style lang="less">
.login {
  width: 100%;
  height: 100%;
  font-size: 1.4rem;
}

.login-main {
  width: 100%;
  overflow: hidden;
}

.login-content {
  width: 200%;
  transition: all .3s;

  &.show-login {
    transform: translateX(0);
  }

  &.show-register {
    transform: translateX(-50%);
  }
}

.login-operation {
  display: inline-block;
  width: 50%;
  padding: 5%;

  &.login {
    position: relative;
    top: -7rem;
  }
}

.login-tab {
  position: relative;
  height: 6rem;
  margin: 0 auto;
  font-family: mine-font-En1;

  .login-tab-item {
    position: relative;
    display: inline-block;
    padding: 0 1rem;
    height: 6rem;
    line-height: 5rem;
    transform: skewX(-30deg);
    width: 14rem;
    background-color: @background-color;
    overflow: hidden;

    span {
      font-size: 1.8rem;
      height: 2rem;
      padding: 0 1rem;
      position: absolute;
      transform: skewX(30deg);
      transition: all .3s;
    }

    &:nth-of-type(1) {
      border-right: 2px solid @login-tab-beveling-line-color;

      span {
        right: -1rem;
      }

      &.active {
        span {
          transition: all .6s;
          right: 2.6rem;
          transform: skewX(30deg) scale(2);
        }
      }
    }

    &:nth-of-type(2) {
      span {
        left: -5rem;
      }

      &.active {
        span {
          transition: all .6s;
          left: .5rem;
          transform: skewX(30deg) scale(2);
        }
      }
    }
  }
}

.wi-input {
  margin-bottom: 1.5rem;
}

.copyright {
  position: absolute;
  width: 100%;
  bottom: 0;

  a {
    display: block;
    width: 100%;
    font-size: 1.2rem;
    line-height: 3rem;
    color: @copyright-color !important;
  }
}
</style>
