<template>
  <div>
    <div v-if="loading" class="excerpt-group-data-list">
      <div v-for="item in 8" :key="item" class="mt-1 p-1">
        <van-skeleton :row="1" title></van-skeleton>
      </div>
    </div>
    <div class="excerpt-group-data-list" v-else-if="dataList.length">
      <draggable v-bind="dragOptions" :handle="'.icon-drag'" @start="starDrag" @end="endDrag">
        <div class="excerpt-group-data-list-item dfr-Y-center wi-card excerpt-group-data-list-item-all"
             @click="$router.push({path: '/excerpt-list', query: {title: 'ALL'}})">
          <div class="excerpt-group-detail dfr-Y-center">
            <i class="iconfont icon-zhiding1"></i>
            <div class="dfr-Y-center">
              <p class="excerpt-group-detail-label">ALL</p>
              <p class="excerpt-group-detail-count">有<span>{{ totalCount }}</span>条摘抄</p>
            </div>
          </div>
        </div>
        <div v-for="(item, i) in dataList"
             :key="item.id"
             class="excerpt-group-data-list-item dfr-Y-center wi-card"
             @click="$router.push({path: '/excerpt-list', query: {groupID: item.id, title: item.label}})">
          <div class="excerpt-group-detail dfr-Y-center">
            <i class="iconfont icon-tuodong1 icon-drag"></i>
            <div>
              <p class="excerpt-group-detail-label">{{ item.label }}</p>
              <p class="excerpt-group-detail-count">有<span>{{ item.count }}</span>条摘抄</p>
            </div>
          </div>
          <i class="icon-more iconfont icon-gengduo-shuxiang" @click.stop="handMore(i)"></i>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'ExcerptGroupDataList',
  components: {draggable},
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    dataList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      dragOptions: {
        animation: 500,
        handle: '.excerpt-group-data-list-item',
        filter: '.excerpt-group-data-list-item-all'
      },
      drag: false
    }
  },
  computed: {
    totalCount() {
      let count = 0
      this.dataList.forEach(item => {
        count += parseInt(item.count)
      })
      return count
    }
  },
  created() {
  },
  methods: {
    handMore(index) {
      this.$emit('handleMore', index)
    },
    starDrag(){
      console.log(this.$parent)
      this.$parent.$parent.changePullRefreshStatus(true)
    },
    endDrag(){
      this.$parent.$parent.changePullRefreshStatus(false)
    }
  }
}
</script>

<style scoped lang="less">
.excerpt-group-data-list {
  padding: 0 1rem 1rem 1rem;
}

.excerpt-group-data-list-item {
  position: relative;
  padding: 1rem;
  height: 6.4rem;
  justify-content: space-between;

  &:not(:first-of-type) {
    margin-top: 1rem;
  }
}

.excerpt-group-detail {
  position: relative;
  z-index: 1;

  .excerpt-group-detail-label {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  .excerpt-group-detail-count {
    font-size: 1.2rem;
    color: @gray-7;
    line-height: 2.4rem;
  }
}

.excerpt-group-detail i {
  padding-right: 1rem;
}

.icon-more {
  font-size: 2rem;
}

.van-skeleton__title + .van-skeleton__row {
  margin-top: 1rem;
}
</style>
