<template>
  <div>
    <!--骨架屏：加载中且数据为空时显示-->
    <ul class="day-data-list dfr"  v-if="loading && !dataList.length">
      <li v-for="item in 8" :key="item" class="day-data-list-item">
        <van-skeleton :row="3" class="dfc-XY-center"></van-skeleton>
      </li>
    </ul>
    <!--数据-->
    <ul class="day-data-list dfr" v-else>
      <li v-for="(item, i) in dataList" class="day-data-list-item">
        <div class="day-detail wi-card dfc-XY-center text-center">
          <i class="icon-top iconfont icon-zhiding1" v-show="item.isTop"></i>
          <i class="icon-more iconfont icon-gengduo-shuxiang" @click="handMore(i)"></i>
          <p class="day-detail-label van-multi-ellipsis--l2">{{ item.label }}</p>
          <p class="day-detail-date">第 <span>{{ item.date | computedDays }}</span> 天</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'DayDataList',
  components: {},
  props: {
    dataList: {
      type: Array,
      default: [],
      required: true
    },
    loading: Boolean
  },
  data() {
    return {}
  },
  computed: {},
  created() {
  },
  methods: {
    handMore(index){
      this.$emit('handleMore', index)
    }
  }
}
</script>

<style scoped lang="less">
.day-data-list {
  padding: 1rem 1rem 0 1rem;
  flex-wrap: wrap;
}

.day-data-list-item {
  width: 50%;
  padding-bottom: 1rem;

  &:nth-of-type(2n) {
    padding-left: .5rem;
  }

  &:nth-of-type(2n-1) {
    padding-right: .5rem;
  }
}

.day-detail {
  position: relative;
  height: 15rem;

  i {
    position: absolute;
    top: .5rem;
    font-size: 2rem;

    &.icon-top {
      left: .5rem;
    }

    &.icon-more {
      right: .2rem;
    }
  }

  .day-detail-label{
    font-size: 2.6rem;
    padding: 0 2rem;
  }

  .day-detail-date{
    font-size: 1.4rem;
    line-height: 3rem;
    margin-top: .5rem;

    > span {
      font-weight: 400;
      font-size: 2rem;
    }
  }
}

.van-skeleton{
  padding: 1rem !important;
  height: 15rem;
}
</style>
