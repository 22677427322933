import {fetchGet, fetchPost} from '@/util/request'

// 获取首页随机摘抄
export function getRandomExcerpt() {
  return fetchGet('/excerpt/getRandomExcerpt')
}

// 获取摘抄列表
export function getExcerptList(data) {
  return fetchGet('/excerpt/getDataList', data)
}

// 获取摘抄详情
export function getExcerptDetail(data) {
  return fetchGet('/excerpt/getExcerptDetail', data)
}

// 删除摘抄
export function delExcerpt(data) {
  return fetchPost('/excerpt/delExcerpt', data)
}

// 修改摘抄
export function updateExcerpt(data) {
  return fetchPost('/excerpt/updateExcerpt', data)
}

// 切换分组
export function changeExcerptGroup(data) {
  return fetchPost('/excerpt/changeGroup', data)
}

// 添加摘抄
export function addExcerpt(data) {
  return fetchPost('/excerpt/addExcerpt', data)
}

// 获取纪念日
export function getDayList() {
  return fetchGet('/theDay/getDataList')
}

// 修改纪念日
export function updateTheDay(data) {
  return fetchPost('/theDay/updateTheDay', data)
}

// 删除纪念日
export function delTheDay(data) {
  return fetchPost('/theDay/delTheDay', data)
}

// 置顶纪念日
export function setTopTheDay(data) {
  return fetchPost('/theDay/setTop', data)
}

// 获取摘抄分组
export function getExcerptGroupList() {
  return fetchGet('/excerptGroup/getDataList')
}

// 修改摘抄分组信息
export function updateExcerptGroup(data) {
  return fetchPost('/excerptGroup/updateExcerptGroup', data)
}

// 删除摘抄分组
export function delExcerptGroup(data) {
  return fetchPost('/excerptGroup/delExcerptGroup', data)
}
