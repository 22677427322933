<template>
  <div class="home-date dfr">
    <p class="day">{{ day }}</p>
    <p class="month">{{ monthList[month] }}.</p>
    <p class="year">{{ year }}</p>
  </div>
</template>

<script>
export default {
  name: 'HomeDate',
  components: {},
  data() {
    return {
      monthList: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
      year: new Date().getFullYear(),
      month: new Date().getMonth(),
      day: new Date().getDate(),
    }
  },
  computed: {},
  created() {
  },
  methods: {}
}
</script>

<style scoped lang="less">
.home-date {
  width: 100%;
  justify-content: flex-end;
  padding: 0 1.5rem;
  position: relative;
  font-family: mine-font-En2;
  align-items: flex-end;

  p {
    text-align: center;
    font-size: 2rem;
    font-weight: bolder;

    &.day {
      font-size: 3.2rem;
      font-weight: bold;
      padding-right: .5rem;
      line-height: 4rem;
      transform: scale(1.2, 1.5);
      -ms-transform: scale(1.2, 1.5);
      -webkit-transform: scale(1.2, 1.5);
      -moz-transform: scale(1.2, 1.5);
      -o-transform: scale(1.2, 1.5);
    }

  }
}
</style>
