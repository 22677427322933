<template>
  <div class="home dfc">
    <div class="home-main dfc-XY-center">
      <home-date></home-date>
      <van-skeleton :row="3" :loading="!excerptList.length" class="dfr-XY-center">
        <van-swipe class="my-swipe" :autoplay="0" :show-indicators="false">
          <van-swipe-item v-for="(item, i) in excerptList" :key="i">
            <div class="home-excerpt-card dfc-X-center">
              <div class="excerpt-detail dfc-XY-center p-1">
                <div class="excerpt-content" v-html="initExcerpt(item.content)"></div>
                <p class="excerpt-from" v-if="item.author || item.book || item.chapter">
                  ——
                  <span v-if="item.author">{{ item.author }}</span>
                  <span v-if="item.book">《{{ item.book }}》</span>
                </p>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </van-skeleton>
    </div>
  </div>
</template>

<script>
import HomeDate from './components/home-date'
import {initExcerpt} from '@/util/init-data'
import {getRandomExcerpt} from '@/api/data'
import {getToken} from '@/util/token'

export default {
  name: 'Home',
  components: {HomeDate},
  data() {
    return {
      excerptList: [],
      token: getToken()
    }
  },
  computed: {},
  created() {
  },
  activated() {
    if (this.token !== getToken() || !this.excerptList.length) { // 防止用户切换缓存
      this.excerptList = []
      this.getRandomExcerpt()
    }
  },
  methods: {
    initExcerpt,
    async getRandomExcerpt() {
      const res = await getRandomExcerpt()
      this.excerptList = res.data || []
    }
  }
}
</script>

<style scoped lang="less">
.home {
  position: relative;
  z-index: 2;
  height: 100%;

  .home-main {
    flex: 1;
  }

  .van-swipe {
    width: 100%;
    padding-bottom: 2rem;
    height: 90%;

    .van-swipe-item {
      padding: 1rem;
      width: 100%;
      height: 100%;
    }
  }

  .van-skeleton{
    width: 100%;
    height: 90%;
    padding: 1rem;
  }
}

.home-excerpt-card {
  height: 100%;
  overflow: hidden;

  .excerpt-detail {
    height: 0;
    flex: 1;

    .excerpt-content {
      font-size: 1.6rem;
      text-indent: 3.2rem;
      line-height: 3rem;
      padding: 1rem;
    }

    .excerpt-from {
      width: 100%;
      font-size: 1.2rem;
      line-height: 3rem;
      text-align: right;
    }
  }
}
</style>
