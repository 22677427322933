<template>
  <div class="excerpt-group">
    <van-pull-refresh v-model="refreshLoading" @refresh="getExcerptGroupList" :disabled="disabledPullRefresh">
      <no-data v-if="!loading&&!excerptGroup.length"></no-data>
      <excerpt-group-data-list v-else :data-list="excerptGroup" :loading="loading" @handleMore="handleMore"></excerpt-group-data-list>
    </van-pull-refresh>

    <side-tool-button :icon="'icon-add'" :bottom="'9.5rem'" @click="handleAdd"></side-tool-button>
    <van-popup v-model="popupInfo.show" position="bottom" round @closed="hidePopup">
      <h4 class="popup-title text-center">{{ popupInfo.title }}</h4>
      <div class="popup-content p-1" v-if="popupInfo.type === 1">
        <setting-plug @click="handleEdit" label="编辑" icon="icon-bianji"></setting-plug>
        <setting-plug @click="handleDelete" label="删除" icon="icon-shanchu-m"></setting-plug>
      </div>
      <div class="popup-content p-1" v-if="popupInfo.type === 2">
        <setting-plug label="名称" class="mb-1">
          <wi-input v-model="selectData.label" placeholder="请输入分组名" :max-length="30"></wi-input>
        </setting-plug>
        <wi-button @click="updateExcerptGroup">确认</wi-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import excerptGroupDataList from './components/excerpt-group-data-list'
import {getExcerptGroupList, updateExcerptGroup, delExcerptGroup} from '@/api/data'
import {deepCopy} from '@/util/common'


import {mapGetters} from 'vuex'
export default {
  name: 'ExcerptGroup',
  components: {excerptGroupDataList},
  data() {
    return {
      excerptGroup: null,
      selectData: null,
      popupInfo: {
        show: false,
        title: '',
        type: '' // 1 操作  2 修改
      },
      selectIndex: null,
      loading: false, // 数据请求完成
      refreshLoading: false,
      scrollTop: 0,
      disabledPullRefresh: false // 禁用下拉刷新
    }
  },
  computed: {
    ...mapGetters({
      mainScrollTop: 'mainScrollTop',
      inScrollBottom: 'inScrollBottom'
    })
  },
  created() {
    this.getExcerptGroupList()
  },
  activated() {
    this.hidePopup()
    document.querySelector('.mine-page').scrollTop = this.scrollTop
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'ExcerptList') {
      this.scrollTop = 0
    } else {
      this.scrollTop = this.mainScrollTop
    }
    next()
  },
  methods: {
    async getExcerptGroupList() {
      this.loading = true
      const res = await getExcerptGroupList()
      this.loading = false
      this.refreshLoading = false
      this.excerptGroup = res.data || []
    },
    async delExcerptGroup(id) {
      const res = await delExcerptGroup({id})
      this.$toast(res.msg)
      await this.getExcerptGroupList()
    },
    async updateExcerptGroup() {
      if (!this.selectData.label) {
        this.$toast('请输入分组名！')
        return
      }
      const res = await updateExcerptGroup(this.selectData)
      if (res.code === 0) {
        this.$toast(res.msg)
        await this.getExcerptGroupList()
        this.hidePopup()
      }
    },
    handleMore(index) {
      this.selectData = deepCopy(this.excerptGroup[index])
      this.showPopup(1, this.selectData.label)
      this.selectIndex = index
    },
    handleEdit() {
      this.showPopup(2, '修改分组')
    },
    handleAdd() {
      this.showPopup(2, '添加分组')
      this.selectData = {
        id: null,
        label: ''
      }
    },
    handleDelete() {
      if (this.selectData.count) {
        this.$toast('请先清空分组！')
        return
      }
      let groupId = this.selectData.id
      this.hidePopup()
      this.$dialog.confirm({
        closeOnClickOverlay: false,
        message: '是否删除该分组？',
      }).then(_ => {
        this.delExcerptGroup(groupId)
      }).catch(_ => {

      })
    },
    showPopup(type, title) {
      this.popupInfo = {
        show: true,
        title,
        type
      }
    },
    hidePopup() {
      this.popupInfo = {
        show: false,
        title: '',
        type: ''
      }
      this.selectData = null
      this.selectIndex = null
    },
    changePullRefreshStatus(status){
      this.disabledPullRefresh = status
    }
  }
}
</script>
