<template>
  <div class="excerpt-list">
    <van-pull-refresh v-model="refreshLoading" @refresh="getExcerptList">
      <no-data v-if="!loading&&!excerptList.length"></no-data>
      <excerpt-data-list v-else :data-list="excerptList" :loading="loading" @handleMore="handleMore"></excerpt-data-list>
      <div class="wi-loading" v-show="pageNum < lastPage">
        <p>加载中……</p>
      </div>
    </van-pull-refresh>
    <van-popup v-model="popupInfo.show" position="bottom" round @closed="hidePopup">
      <h4 class="popup-title text-center">{{ popupInfo.title }}</h4>
      <div class="popup-content p-1" v-if="popupInfo.type === 1">
        <setting-plug @click="handleMove" label="移动" icon="icon-yidong"
                      :disabled="excerptGroup.length < 2"></setting-plug>
        <setting-plug @click="handleEdit" label="修改" icon="icon-bianji"></setting-plug>
        <setting-plug @click="handleDelete" label="删除" icon="icon-shanchu-m"></setting-plug>
      </div>
      <div class="popup-content p-1" v-if="popupInfo.type === 2">
        <setting-plug label="分组列表">
          <ul class="excerpt-group-select-list dfr">
            <li v-for="(item, i) in excerptGroup"
                :key="i"
                v-if="item.id != selectData.groupID"
                class="excerpt-group-select-list-item dfr-XY-center"
                :class="{active: selectGroupID === item.id}"
                @click="selectGroupID = item.id">
              <i class="iconfont mr-1"
                 :class="selectGroupID === item.id ? 'icon-radio-checked': 'icon-danxuan'"></i>
              <p>{{ item.label }}</p>
            </li>
          </ul>
        </setting-plug>
        <wi-button @click="updateData('changeGroup')">确认</wi-button>
      </div>
      <div class="popup-content p-1" v-if="popupInfo.type === 3">
        <setting-plug label="内容">
          <textarea class="mine-textarea" v-model="selectData.content" placeholder="请输入摘抄内容" rows="5"></textarea>
        </setting-plug>
        <setting-plug label="作者">
          <wi-input placeholder="请输入作者名称" v-model="selectData.author"></wi-input>
        </setting-plug>
        <setting-plug label="所属书籍">
          <wi-input placeholder="请输入所属书籍" v-model="selectData.book"></wi-input>
        </setting-plug>
        <setting-plug label="所在章节" class="mb-1">
          <wi-input placeholder="请输入所在章节" v-model="selectData.chapter"></wi-input>
        </setting-plug>
        <wi-button @click="updateData">确认</wi-button>
      </div>
    </van-popup>
    <side-tool-button :icon="'icon-add'"
                      :bottom="'9.5rem'"
                      @click="handleAdd"
                      v-if="$route.query.title !== 'ALL'">
    </side-tool-button>
  </div>
</template>

<script>
import {goTop, deepCopy, pageScrollTop} from '@/util/common'
import {mapGetters} from 'vuex'

import excerptDataList from './components/excerpt-data-list'
import {
  getExcerptList,
  updateExcerpt,
  delExcerpt,
  changeExcerptGroup,
  getExcerptGroupList,
  addExcerpt
} from '@/api/data'

export default {
  name: 'ExcerptList',
  components: {excerptDataList},
  data() {
    return {
      excerptList: [],
      excerptGroup: [],
      groupID: null,
      scrollTop: 0,
      selectData: null,
      popupInfo: {
        show: false,
        title: '',
        type: '' // 1 操作  2 修改
      },
      selectGroupID: null,
      pageSize: 20,
      pageNum: 1,
      lastPage: 1,
      loading: false,
      refreshLoading: false,
      selectIndex: null
    }
  },
  computed: {
    ...mapGetters({
      mainScrollTop: 'mainScrollTop',
      inScrollBottom: 'inScrollBottom'
    })
  },
  watch: {
    inScrollBottom() {
      if (this.inScrollBottom && !this.loading) {
        this.pageNum++
        if (this.pageNum > this.lastPage) {
          return
        }
        this.getExcerptList(1)
      }
    }
  },
  created() {
    this.initData()
    this.getExcerptGroupList()
  },
  activated() {
    this.getExcerptGroupList()
    this.hidePopup()
    if (this.groupID !== (parseInt(this.$route.query.groupID) || null)) {
      this.excerptList = []
      this.initData()
    }
    pageScrollTop(this.scrollTop)
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'ExcerptDetailPage') {
      this.scrollTop = 0
    } else {
      this.scrollTop = this.mainScrollTop
    }
    next()
  },
  methods: {
    async getExcerptGroupList() {
      const res = await getExcerptGroupList()
      this.excerptGroup = res.data || []
    },
    async getExcerptList(type = 0) { // 0最新 1加载更多
      if (!type) this.loading = true // 加载更多时不显示骨架屏
      this.pageNum = type ? this.pageNum : 1
      const res = await getExcerptList({
        groupID: this.groupID || null,
        pageSize: this.pageSize,
        pageNum: this.pageNum
      })
      if (!type) {
        this.excerptList = res.data.data || null
        goTop()
      } else {
        this.excerptList = this.excerptList.concat(res.data.data)
      }
      this.lastPage = res.data.last_page || 1
      this.loading = false
      this.refreshLoading = false
    },
    async changeGroup(id, newGroupID) {
      const res = await changeExcerptGroup({
        id,
        newGroupID
      })
      this.$toast(res.msg)
      this.removeData()
      this.hidePopup()
    },
    async addExcerpt() {
      const res = await addExcerpt(this.selectData)
      this.$toast(res.msg)
      await this.getExcerptList()
      this.hidePopup()
    },
    async delExcerpt(id) {
      const res = await delExcerpt({
        id
      })
      this.$toast(res.msg)
      this.removeData()
    },
    async updateExcerpt() {
      const res = await updateExcerpt(this.selectData)
      this.$toast(res.msg)
      this.excerptList[this.selectIndex] = this.selectData
      this.hidePopup()
    },
    initData() {
      this.groupID = parseInt(this.$route.query.groupID) || null
      this.pageNum = 1
      this.lastPage = 1
      this.getExcerptList()
    },
    showPopup(type, title) {
      this.popupInfo = {
        show: true,
        title,
        type
      }
    },
    hidePopup() {
      this.popupInfo = {
        show: false,
        title: '',
        type: ''
      }
      this.selectData = null
      this.selectGroupID = null
      this.selectIndex = null
    },
    handleMore(index) {
      this.selectIndex = index
      this.selectData = deepCopy(this.excerptList[index])
      this.showPopup(1, '编辑')
    },
    handleMove() {
      this.showPopup(2, '移动')
    },
    handleEdit() {
      this.showPopup(3, '修改')
    },
    handleDelete() {
      let excerptID = this.selectData.id
      this.$dialog.confirm({
        closeOnClickOverlay: false,
        overlayStyle: {
          background: 'rgba(0, 0, 0, 0)'
        },
        message: '是否删除该摘抄？',
      }).then(_ => {
        this.delExcerpt(excerptID)
      }).catch(err => {
        // 没什么用 防报错
      })
    },
    handleAdd() {
      this.showPopup(3, '添加')
      this.selectData = {
        content: '',
        author: '',
        book: '',
        chapter: '',
        groupID: this.groupID
      }
    },
    updateData(type) {
      // type: changeGroup 移至其他分组
      if (type === 'changeGroup') {
        if (!this.selectGroupID) {
          this.$toast('请选择目标分组！')
        } else {
          this.changeGroup(this.selectData.id, this.selectGroupID)
        }
      } else {
        if (!this.selectData.content) {
          this.$toast('请输入内容！')
          return
        }
        if (!this.selectData.id) {
          this.addExcerpt()
        } else {
          this.updateExcerpt()
        }
      }
    },
    removeData() {
      let removeIndex = null
      this.excerptList.forEach((item, i) => {
        if (item.id === this.selectData.id) {
          removeIndex = i
        }
      })
      if (removeIndex !== null) {
        this.excerptList.splice(removeIndex, 1)
      }
      this.hidePopup()
    }
  }
}
</script>

<style lang="less">
.excerpt-list {
  .mine-textarea {
    box-sizing: border-box;
    resize: none;
    width: 100%;
    border: none;
    border-radius: @border-radius;
    background-color: @gray-3;
    padding: 1rem;
  }
}


.excerpt-group-select-list {
  flex-wrap: wrap;
  margin-bottom: 1rem;

  .iconfont {
    font-size: 1.4rem !important;
  }

  .excerpt-group-select-list-item{
    height: 2.6rem;
    border-radius: 1.3rem;
    padding: 0 1rem;
    margin-bottom: 1rem;
    &.active{
      background-color: tint(@foreground-color1, 80%);
      color: @foreground-color1;
    }
  }
}
</style>
