<template>
  <div class="user-info">
    <van-tabs v-model="activeName" v-show="true">
      <van-tab :title="item.title" :name="item.key" v-for="item in settingType" :key="item.key"></van-tab>
    </van-tabs>
    <div class="user-info-content wi-card" v-if="userInfo">
      <div class="setting-box" v-if="activeName === 'baseInfo'">
        <div class="setting-item dfr-X-center">
          <div class="user-avatar">
            <div class="img-box">
              <div :style="`background-image:url(${userInfo.avatarImg || defaultAvatarImg});`"></div>
            </div>
          </div>
        </div>
        <div class="setting-item">
          <h4>头像</h4>
          <wi-input v-model="userInfo.avatarImg" :placeholder="'请输入头像地址'"></wi-input>
        </div>
        <div class="setting-item">
          <h4>昵称</h4>
          <wi-input v-model="userInfo.userName" disabled></wi-input>
        </div>
        <div class="setting-item">
          <h4>邮箱</h4>
          <wi-input v-model="userInfo.eMail" disabled></wi-input>
        </div>
        <wi-button @click="updateUserInfo">保存</wi-button>
      </div>
      <div class="setting-box" v-if="activeName === 'password'">
        <div class="setting-item">
          <h4>原始密码</h4>
          <wi-input
              :type="'password'"
              :placeholder="'请输入原始密码'"
              v-model="password"
              :max-length="regexList.password.max"
              :show-err-icon="!checkValue('password')"></wi-input>
        </div>
        <div class="setting-item">
          <h4>新密码</h4>
          <wi-input
              :type="'password'"
              :placeholder="'请输入新密码'"
              v-model="newPassword"
              :max-length="regexList.newPassword.max"
              :show-err-icon="!checkValue('newPassword')"></wi-input>
        </div>
        <div class="setting-item">
          <h4>确认新密码</h4>
          <wi-input
              :type="'password'"
              :placeholder="'请确认新密码'"
              v-model="reNewPassword"
              :max-length="regexList.newPassword.max"
              :show-err-icon="!checkValue('reNewPassword')"></wi-input>
        </div>
        <wi-button @click="updatePassword">保存</wi-button>
      </div>
    </div>
  </div>
</template>

<script>
import md5 from 'js-md5'
import {updateUserInfo, updatePassword, getUserInfo} from '@/api/user'
import {mapGetters} from "vuex";

export default {
  name: 'UserInfo',
  data() {
    return {
      defaultAvatarImg: require('../../assets/images/defaultAvatar.png'),
      settingType: [
        {
          key: 'baseInfo',
          title: '基础信息'
        },
        {
          key: 'password',
          title: '密码修改'
        }
      ],
      activeName: 'baseInfo',
      regexList: { // 正则表
        userName: {
          min: 2,
          max: 8,
          regex: /^[\u4E00-\u9FA5A-Za-z0-9_]+$/,
          tip: '用户名长度为2-8位，仅允许中文、英文、数字以及下划线。'
        },
        password: {
          min: 6,
          max: 16,
          regex: /(?=.*([a-zA-Z].*))(?=.*[0-9].*)[a-zA-Z0-9-*/+.~!@#$%^&*()]{6,16}$/,
          tip: '密码长度为6-16位，仅允许数字、英文、特殊符号，且必须包含数字及英文。'
        },
        newPassword: {
          min: 6,
          max: 16,
          regex: /(?=.*([a-zA-Z].*))(?=.*[0-9].*)[a-zA-Z0-9-*/+.~!@#$%^&*()]{6,16}$/,
          tip: '密码长度为6-16位，仅允许数字、英文、特殊符号，且必须包含数字及英文。'
        },
        eMail: {
          min: 0,
          max: 255,
          regex: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
        }
      },
      password: '',
      newPassword: '',
      reNewPassword: ''
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo'
    })
  },
  created() {
  },
  methods: {
    async getUserInfo(){
      const res = await getUserInfo()
      this.$store.dispatch('setUserInfo', res.data)
    },
    async updateUserInfo() {
      await updateUserInfo({
        avatarImg: this.userInfo.avatarImg
      })
      this.$toast('修改成功！')
      this.getUserInfo()
    },
    async updatePassword() {
      if (!this.password) {
        this.$toast('请输入原始密码！')
        return
      }
      if (!this.checkValue('password')) {
        this.$toast('原始密码格式有误！')
        return
      }
      if (!this.newPassword) {
        this.$toast('请输入新密码！')
        return
      }
      if (!this.checkValue('newPassword')) {
        this.$toast('新密码格式有误！')
        return
      }
      if (!this.reNewPassword) {
        this.$toast('请确认新密码！')
        return
      }
      if (!this.checkValue('reNewPassword')) {
        this.$toast('确认密码有误！')
        return
      }
      if (this.password === this.newPassword) {
        this.$toast('新密码不能与原始密码相同！')
        return
      }
      const res = await updatePassword({
        password: md5(this.password),
        newPassword: md5(this.newPassword)
      })
      this.$toast(res.msg)
      this.logout()
    },
    checkValue(type) {
      let flag = true
      let value = this[type]
      if (!this[type]) {
        return flag
      } else {
        if (type === 'reNewPassword') {
          flag = value === this.newPassword ? true : false
          return flag
        } else {
          let regex = this.regexList[type].regex
          flag = value.length >= this.regexList[type].min && value.length <= this.regexList[type].max && regex.test(value) ? true : false
          return flag
        }
      }
    },
    logout() {
      this.$cookies.remove('TOKEN')
      this.$cookies.remove('USER_INFO')
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped lang="less">
.user-info {
  padding: 0 1rem;

  .user-info-content {
    margin-top: 1rem;
    padding: 1rem;
  }

  .user-avatar {
    padding: 2px;
    border-radius: 50%;
    width: 7rem;
    height: 7rem;

    .img-box {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;

      > div {
        width: 100%;
        height: 100%;
        background-size: cover;
      }
    }

  }

  .setting-item {
    margin-bottom: 1.5rem;

    h4 {
      font-size: 1.4rem;
      font-weight: normal;
      margin-bottom: 1rem;
    }
  }
}
</style>
